.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    overflow: hidden;
}

#content {
    width: 100%;
    min-width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    background: #fff;
}
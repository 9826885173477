.c-slider-mobile{
    max-width: 100%;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    width: 100%;
    position: relative;
}

.c-slider-mobile__item{
    display: inline-block;
    border: 1px solid var(--gray-medium3);
    margin-right: 5px;
}

.c-slider-mobile__image{
    width: 120px;
    height: auto;
    margin: 0 auto;
    display: block;
}

.c-slider-mobile .slick-arrow{
    width: 35%;
    border: none;
    text-indent: 9999999px;
    overflow: hidden;
    height: 50px;
    cursor: pointer;
    position: relative;
    z-index: 10;
    background: transparent;
    outline: none;
    display: flex;
    border:1px solid transparent;
    
    &:hover svg, &:focus{
        fill: var(--primary);
    }

    &:focus{
        border:1px solid var(--primary);

    }
    
    svg{
        width: 100%;
        height: auto;
        display: block;
        fill: #000;
        position: relative;
        top:50%;
        transform: translate(0,-50%);
    }
}


.c-slider-mobile__slide{
    outline: none;
}

.slick-slide{
    outline: none;
}

.slick-dots {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li.slick-active button {    
    background: var(--primary);
}

.slick-dots li button {
    font-family: 'slick';
    font-size: 35px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 8px;
    border: 1px solid var(--primary);
    display: inline-block;
    text-align: center;    
    border-radius: 100%;
    background: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



@media (min-width: $tablet-up){
    .c-slider-mobile{
        text-align: center;
        white-space: inherit;
        overflow-y: visible;
    }

    .c-slider-mobile__item{
        width: 21.6%;
        margin: 7px;
    }
    
    .js-slider-register{
        width: 410px;
        margin: 0 auto;
    }
}

@media (min-width: $tablet){
    .c-slider-mobile__item{
        width: 17.3%;
        @include animation-transition($anim-fast);

        &:hover, &:focus{
            @include box-shadow-hover;
        }

        &:focus{
            border-color: var(--primary);
        }
    }
    
    .js-slider-register{
        top:50%;
        transform: translate(0, -50%);
    }
}

@media (min-width: $res-HD){
    .c-slider-mobile__item{
        width: 17.8%;
    }
}
.logo-login{
    display: block;
    margin: 0 auto 30px;
    padding: 20px;
    width: 200px;
    height: auto;
}

.js-height-screen{
    min-height: 500px; 
}

.c-form{
    width: 85%;
}

.link-login{
    color: var(--primary);

    &:hover, &:focus{
        color: var(--hover-primary);
        text-decoration: underline;
    }
}

.c-form__text{
    border-top: 1px solid var(--gray-medium5);
    padding-top: 25px;
    margin-top: 20px;

}

.title-login{
    font-size: 22px;
}

.c-form__text{
    font-size: 16px;
}

.icon-email{
    width: 70px;
    height: auto;
}

.subtitle-form{
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    color: var(--primary);
}

.c-checklist__item{
    width: 50%;
    float: left;

    label{
        cursor: pointer;
    }

}

.c-checklist__check{
    margin-right: 7px;
}

.c-form--success__icon{
    display: block;
    margin: 0 auto;
    width: 80px;
    height: auto;
    fill: #25AE88;
}

.intl-tel-input{ 
    width: 100%;
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: var(--primary);
    border-color: var(--primary);
}


@media (min-width: $tablet){
    .c-form{
        width: 55%;
        margin: 0 auto;

        &.signin-advertiser{
            width: 61%;
        }
    }

    .title-login{
        font-size: 26px;
    }

    .c-img--login{
        background-image: linear-gradient(#cfcfcf, #dfdfdf);        
        width: 100%;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: top right;
        z-index: 10;
    }

    .icon-email{
        width: auto;
    }
}


.box-red-shadow{
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 26px;
    text-align: center;
    border-radius: 10px;
    padding: 25px;
    box-shadow: 0px 5px 0 var(--primary);
    position: relative;
    width: 510px;
    background: #fff;

    @media (min-width: $tablet){
        width: 320px;
    }

    @media (min-width: $res-HD){
        width: 510px;
    }
}
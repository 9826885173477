.c-footer--dark{
    background: var(--gray-hard2);
    color: #fff;
}

.c-footer-title{
    display: block;
}

.c-simple-list{
    margin-bottom: 0;
}

.c-social-dark{
    padding: 15px;
    background: var(--gray-medium4);
    font-size: 14px;
}

.c-simple-list__link{
    color: #fff;
    font-size: 14px;
    display: inline-block;
    
    &:hover, &:focus{
        text-decoration: underline;
        color: #fff;
    }
}

.c-social-icon{
    position: relative;
    top: -5px;
}

.google-play{
    width: 130px;

    &:focus{
        border: 1px solid;
        outline: 0;
        border-radius: 5px;
    }
}

.img-focus-footer:focus{
    border: 1px solid #fff;
    display: inline-block;
}

.footer-login{
    background-color: var(--gray-medium4);
    position: fixed;
    bottom: 0;
    padding: 10px;
    width: 100%;
}

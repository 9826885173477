.c-card-simple{
    width: 95%;
    margin: 0px auto 50px;
    position: relative;
}

.c-card-simple__title{
    font-size: 24px;
    margin-bottom: 22px;
    display: block;
    text-align: center;
    line-height: 25px;
}

.c-card-simple__description{
    margin-bottom: 30px;
}

.c-card-simple__icon{
    width: 140px;
    height: 140px;
    margin: 0 auto;
}

@media(min-width:$mobile-medium){

    .c-card-simple__icon{
        margin-top: 20px;
        width: 150px;
        height: 150px;
    }
}

@media(min-width:$mobile-big){
    .c-card-simple__icon{
        margin-top: 30px;       
    }
}
@media(min-width:$tablet){
    .c-card-simple__description{        
        margin: 0 auto;
        display: block;
    }
    
    .c-card-simple__title{
        font-size: 40px;
        line-height: 38px;
    }
}
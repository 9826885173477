.c-title-page{
    position: relative;
    background: #000;
    display: block;
    height: 100%;
}

.c-title-page .container, .c-title-page__image{
    height: 12em;
    position: relative;
}

.c-title-page__image{
    content: "";
    display: block;    
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    width: 100%;    
    z-index: 0;
    height: 100%;
}

.c-title-page__image--afiliado{
    background-image: url(../assets/images/afiliados-lomadee.png);
}


.c-title-page__image--anunciantes{
    background-image: url(../assets/images/anunciantes-lomadee.png);
}

.c-title-page__image--lomadee{
    background-image: url(../assets/images/sobre-lomadee.png);
}

.c-title-page__image--mkt-de-afiliados{
    background-image: url(../assets/images/o-que-e-marketing-de-afiliados.png);
}

.c-title-page__image--detalhe-anunciante{
    background-image: url(../assets/images/detalhe-anunciante.jpg);
}

.c-title-page__image--consultor-lomadee{
    background-image: url(../assets/images/banner-consultor-lomadee.png);
}


.c-title-page__text{
    color: #fff;
    position: relative;
    top: 50%;
    transform: translate(0,-50%);
}

.c-title-page__text1{
    font-size: 22px;
    display: block;
}

.c-title-page__text2{
    font-size: 30px;
}

@media (min-width: $tablet-up){
    .c-title-page__text1{
        font-size: 25px;

    }

    .c-title-page__text2{
        font-size: 39px;
    }
}

@media (min-width: 769px){
    .c-title-page .container, .c-title-page__image{
        height: 215px;
    }

    .c-title-page__text{
        margin-left: 30px;
    }
}

@media (min-width: $tablet){
    .c-title-page .container, .c-title-page__image{
        height: 245px;
    }

    .c-title-page{
        padding-top: 60px;
    }
    
    .c-title-page__image{
        background-attachment: fixed;
        background-size: auto;
        background-position: center 50px;        
    }
}

//tamanho da imagem
@media (min-width: 1366px){
    .c-title-page__image{
        background-size: 100%;
    }
}
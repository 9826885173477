/* ---------------------------------------------------
    MENU LATERAL
----------------------------------------------------- */

.sidebar {
    min-width: 240px;
    max-width: 240px;
    background: #fff;
    color: #767676;
    transition: all 0.3s;
    margin-left: -240px;
    background: var(--gray);
}


.sidebar.active {
    margin-left: 0px;
    position: relative;
    z-index: 5;


}

.e-close-menu-mobile{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    content: "";
    right: -240px;
    top: 0;
    display: none;
    
    &.active{
        display: block;
    }
}

.sidebar .sidebar-header {
    padding: 22px 35px 10px 35px;
    text-align: center;

}

.sidebar a{
    color: #767676;
    text-transform: uppercase;
    font-size: 12px;
}

.sidebar .fas, .sidebar .far, .sidebar .fa{
    font-size: 18px;
    margin-right: 5px;
}

.sidebar ul.menu-sidebar {
    padding: 0;    
}

.sidebar .menu-sidebar p {
    color: #fff;
    padding: 10px;
}

.sidebar .menu-sidebar li a {
    padding: 9px 14px 9px 33px;
    display: block;
}

.sidebar .menu-sidebar li a:hover {
    color: #000;
    background: #f1f1f1;
}

.sidebar .menu-sidebar li.active>a,
a[aria-expanded="true"] {
    color: #000;    
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle-menu::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
}

.submenu-sidebar a{
    background: #e0e0e0;
    padding: 12px 14px 12px 65px !important;
}

@media (min-width: 769px) {
    .sidebar {
        display:none;
    }
    .sidebar.active {
        margin-left: 0;
    }
    .sidebarCollapse span {
        display: none;
    }
}

.c-roud-icon{
    display: table-cell;
    vertical-align: middle;
}

.c-roud-icon__item1{
    border: 1px solid rgba(237, 28, 36, .5);
    border-radius: 100%;
    display: inline-block;
    padding: 10px;
    line-height: 0;
}


.c-roud-icon__item2{
    border: 1px solid var(--primary);
    border-radius: 100%;
    display: inline-block;
    padding: 10px;
    line-height: 0;
}

.c-roud-icon__svg{
    fill: var(--primary);
    width: 30px;
    height: 30px;
}

.c-description-icon{
    display: table-cell;   
    vertical-align: middle;
    padding-left: 10px;
}

.c-description-icon__title{
    font-size: 25px;
    display: block;
}

.c-description-icon__subtitle{
    display: block;
}

@media(min-width: $tablet){
    .c-roud-icon__svg{
        width: 32px;
        height: 32px;
    }

    .c-roud-icon__item2{
        padding: 20px;
    }

    .c-description-icon__subtitle{
        font-size: 18px;        
    }
    .c-description-icon__title{
        font-size: 30px;
    }  
}
//helpers classes
html{
    min-height: 100%;
    height:100%;
}
a{
    outline: none;
    &:hover{
        text-decoration: none;
    }
}

body{
    color: var(--gray-hard);
    background: #000;
    min-height:100%;

    @media(min-width: $tablet){
        font-size: 18px;
    }
}

.btn-primary{
    &:hover{
        background: var(--hover-primary);
        border-color: var(--hover-primary);
    }

    &:focus, &:active{
        background: var(--hover-primary);
        border-color: var(--hover-primary);
    }
}

.btn-primary:not(:disabled):not(.disabled):active{
    background: var(--hover-primary);
    border-color: var(--hover-primary);
}

.e-color--hightlight{
    color: var(--red-contrast);
}

.e-color--hightlight2{
    color: var(--primary);
}

.e-color--white{
    color: #fff;
}

.e-color--gray{
    color: var(--gray-medium6);
}

.e-small-title{
    font-size: 18px;
}

//elipse effects
.e-half-elipse--bottom{
    width: 100%;
    height: auto;
    background: rgba(0,0,0,0);
    position: relative;
    z-index: 1;
    vertical-align: top;
    margin-top: -1px;
}


.e-cover-elipse--top{
    margin-top: -25px;
    padding-top: 25px;

    @media(min-width: $tablet-up){
        margin-top: -30px;
        padding-top: 30px;
    }

    @media(min-width: $tablet){
        margin-top: -35px;
        padding-top: 35px;
    }

    @media(min-width: $res-HD){
        margin-top: -65px;
        padding-top: 65px;
    }
}

.e-cover-elipse--top2{
    margin-top: -25px;
    padding-top: 25px;

    @media(min-width: $tablet-up){
        margin-top: -28px;
        padding-top: 28px;
    }

    @media(min-width: $tablet){
        margin-top: -35px;
        padding-top: 35px;
    }

    @media(min-width: $res-HD){
        margin-top: -45px;
        padding-top: 65px;
    }
}

.e-half-elipse--gray{
    fill: var(--gray);
}

.e-half-elipse--white{
    fill: #fff;
}


//efect image paralax
.e-photo-scrolling{
    content: "";
    display: block;
    position: relative;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;    
    width: 100%;

    z-index: 0;

    @media(min-width: $tablet-up){

        background-attachment: fixed;
    }
}


.e-photo-scrolling--home{
    height: 15em;
    position: absolute;

    @media(min-width: $tablet-up){
        height: 20em;
    }
}

.e-photo1{
    background-image: url(../assets/images/novidades-lomadee.png);
}

.e-photo2{
    background-image: url(../assets/images/premios-lomadee.png);
    content: "";
    display: block;    
    background-size: auto;
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 100%;    
    z-index: 0;
    height: 100%;
    background-color: #06091E;

    @media (min-width: $tablet){
        background-size: 100%;
        background-position: bottom center;
    }
}

.e-zindex-2{
    position: relative;
    z-index: 2;
}

.e-background-gray-hard{
    background-color: #000;
}

.e-diagonal-division{
    width: 100%;
    height: auto;    
}

.e-link{
    color: var(--blue) !important;
}

.error-message-text{
    color: red;
}

.e-link-text{
    color: var(--gray-hard);
    text-decoration: underline;

    &:hover, &:focus{
        color: var(--primary);
        text-decoration: none;

    }
}

.e-cursor-hover{cursor: pointer;}

.e-btn--big{
    padding: .575rem 1.75rem;
    border-radius: 40px;
    font-size: 18px;
}

.e-lh-29{
    line-height: 29px;
}

.e-svg-fill--primary{
    fill: var(--primary);
}

.e-svg-fill--white{
    fill: #fff;
}

.e-fs-16{
    font-size: 16px;
}

.e-fs-12{
    font-size: 12px;
}

.e-fs-highlight{
    font-size: 23px;

    @media(min-width:$tablet){
        font-size: 30px;
    }
}

.e-diagonal-division2{
    width: 100%;
    height: auto;
    vertical-align: bottom;
}

.e-logo-anunciante{
    background: #fff;
    border-radius: 3px;
    width: 135px;
    padding: 10px;
}

.iti-flag {
    background-image: url(../assets/images/flags.png);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti-flag {
        background-image: url(../assets/images/flags@2x.png);
    }
}

.e-full-screen{
    @media(min-width:$tablet){
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
    }
}


.e-page-not-found{
    font-size: 22px;
}

.e-language-site{
    font-size: 14px;
    color:#9f9f9f;

    select{
        background: #333333;
        border-radius: 3px;
        padding: 3px 5px;
        color: #9f9f9f;
        border: 0;
        transition: all .10s ease-in-out;

        &:hover{
            background: #fff;
            color: #333;
        }
    }
}

.e-bg-gray{
    background:#F3F3F3;
}

.banner-t-group{
    background: url(https://www.tgroup.com.br/assets/banner-tgroup-mobile.gif) #000 no-repeat center;
    width: 100%;
    height: 60px;
    display: block;
}

@media (min-width: $tablet-up){
    .banner-t-group{
        background: url(https://www.tgroup.com.br/assets/banner-tgroup.gif) #000 no-repeat center;
        height: 80px;
        background-size: 140%;
    }
}

@media (min-width: 769px){
    .banner-t-group{
        background: url(https://www.tgroup.com.br/assets/banner-tgroup.gif) #000 no-repeat center;
        height: 80px;
        background-size: 140%;
    }
}

@media (min-width: $tablet){
    .banner-t-group{
        height: 80px;
        background-size: 100%;
    }
}

@media (min-width: $res-HD){
    .banner-t-group{
        height: 130px;
        background-size: auto;
    }
}


.no-fixed .header{
    position: relative!important;
    background: rgb(49, 49, 49)!important;
}

.no-fixed-background .c-title-page__image{
    background-attachment: initial !important;
}

.embed-responsive video{
    outline: none;
}

.e-eye-password{
    cursor:pointer;
    display:none;
    margin-left: -35px;
    margin-top: 13px;
    font-size: 23px;
}

i.e-icon-check-error{
    margin-left: -35px;
    margin-top: 0;
    font-size: 27px;
    display: none;
}
.e-icon-check-error::before{
    top: 9px;
    position: relative;
}

.form-control{
    padding: .375rem 2.2rem .375rem 0.75rem;
    height: calc(2em + .75rem + 2px);
}

.color-red{
    color: var(--primary)
}


.e-box-sec{
    border: 1px solid var(--gray-medium6);
    padding: 10px;
    font-weight: bold;
}
.c-banners{
    background: #000;
    position: relative;
    overflow: hidden;
    height: 165px;
}

.banner-aniversario{
    width: 100%;
    a, img{
        width: 100%;
    }
    
    @media (min-width: 769px){
        margin-top: 60px;
    }
}

.c-banner__item{
    background: #000;    
    width: 50%;
    transform: skew(-15deg);
    overflow: hidden;
    position: absolute;

    &:hover .c-banner__img{
        opacity: .4;
    }

    &:focus .c-banner__text{
        text-decoration: underline;
    }
}

.c-banner__item--left{
    left: -10%;
    width: 60%;


}
.c-banner__item--right{
    right: -10%;
    width: 60%;

    &:after{
        position: absolute;
        width: 2px;
        left: 0%;
        height: 100%;
        background: #fff;
        content: '';
        top:0;
    }
}

.c-banner__img{
    width: 100%;
    height: auto;
    opacity: .5;
    transform-origin: bottom;
    transition: opacity $anim-fast;
}

.c-banner__img--left{
    transform: skew(15deg) scale(1.25) translateX(60px);
}

.c-banner__img--right{
    transform: skew(15deg) scale(1.1) translateX(0px) scale(1.08);
}

.c-banner__text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) skew(15deg);
    z-index: 2;
    color: #fff;    
}

.c-banner__text1{
    font-size: 18px;
    display: block;

}

.c-banner__text2{
    font-size: 24px;
}

.c-banner__text--right{
    left: 40%;
}

@media (min-width:$tablet-up){
    .c-banner__text1{
        font-size: 24px;
    }

    .c-banner__text2{
        font-size: 35px;
    }

    .c-banner__img--left{
        transform: skew(15deg) scale(1.25) translateX(140px);
    }

}

@media (min-width:$tablet){
    .c-banner__text1{
        font-size: 29px;
    }

    .c-banner__text2{
        font-size: 49px;
    }
}

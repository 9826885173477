
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    position: absolute;
    top: -40%;
  }

  to {
    opacity: 1;
    position: absolute;
    top: 0%;
  }
}

@keyframes fadeInUp {
 from {
    opacity: 0;
    position: absolute;
    top: -40%;
  }

  to {
    opacity: 1;
    position: absolute;
    top: 0%;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print), (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
/* -------
COLORS
--------------*/
:root{
--hover-primary: #97004a; 
--red-contrast: #bb004e; 

--color-secundary: #FF9900; 
--hover-secundary: #F19100;

--color-tertiary: #5d76b6;

--color-quartenary: #20CFB2;

--gray: #f6f6f6; 

--gray-medium: #e7e7e7;
--gray-medium2: #989696; 
--gray-medium3: #D8D8D8;
--gray-medium4: #444444;
--gray-medium5: #9F9F9F;
--gray-medium6: #dedede;

--gray-hard: #474747;
--gray-hard2: #333333; 
--gray-hard3: #969696;
--gray-hard4: #080923;

--dark: #1b1b1b;

--color-facebook: #37559C;
--hover-facebook: #2d4682;
}



/* -------
MEDIA QUERY
--------------*/
$mobile-medium: 375px;
$mobile-big: 425px;
$tablet-up: 768px;
$tablet: 992px;
$res-HD: 1200px;

/* -------
ANIMATION
--------------*/
$anim-slow: 1s;
$anim-normal: .3s;
$anim-fast: .15s;
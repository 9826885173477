.c-section{
    padding: 20px 0;
    position: relative;
    z-index: 1;
    
}

.c-title-section{
    font-size: 23px;
    text-align: center;
    font-weight: normal;
}

.c-description-section{
    margin-bottom: 20px;
}

.c-section--gray{
    background-color: var(--gray);
}

.c-section--dark{
    background-color: var(--gray-hard4);
    color: #fff;
}

.c-section--extra-dark{
    background-color: var(--dark);
    color: #fff;
}

.c-section--extra-dark a{
    color:#fff;
}

@media(min-width: $tablet-up){
    .c-title-section{
        font-size: 30px;
        line-height: 33px;
    }
}

@media(min-width: $tablet){
    
    .c-section{
        padding: 40px 0;
    }
    
    .c-description-section{
        width: 85%;
        margin: 0 auto 40px;
        font-size: 18px;
        line-height: 29px;
    }
    
    .c-description-section2{
        width: 820px;
    }
}
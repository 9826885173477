/* ---------------------------------------------------
    HEADER DEFAULT
----------------------------------------------------- */
.header{
    display: block;
    width: 100%;
    z-index: 5;
    background: #313131;
    padding: 10px 0;
    
    @include animation-transition($anim-fast);
}

.header-especial-lomadee .header{
    background: #313131 !important;
}

.logo{
    position: relative;
    left: 51%;
    transform: translate(-82%,0);
    display: inline-block;
    width: 145px;
    height: auto;
}

.nav-header{
    display: inline-block;
}

.btn-menu-mobile{
    display: inline-block;
    color: #fff;    
    padding: 10px;
}

.btn-menu-mobile .fa-bars{
    font-size: 18px;
}

.icon-bar {
    background-color: #fff;
}

.icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
}

.icon-bar + .icon-bar {
    margin-top: 4px;
}


.c-menu-desktop{
    display: none;
}

.c-menu-desktop__link{
    color: #fff;
    padding: 15px;
    font-size: 16px;
    
    @include animation-transition($anim-fast);

    &:hover, &:focus{
        color: red;
        text-decoration: none;
    }
   
}

@media (min-width:769px){
    .btn-menu-mobile{
        display: none;
    }

    .c-menu-desktop{
        display: block;
        margin: 0;
    }
    

    .header{
        padding: 13px;
        position: fixed;        
    }

    .header-especial-lomadee {

        .header{
            position: relative;
    }

        .c-title-page__image{
            background-attachment: inherit;
        }

        .c-banner-campanha__item{
            background-image: linear-gradient(45deg, #3d0a11, #141029);
            
            .c-banner-campanha__img{
                margin: 0 auto;
                display: block;

            }
        }
    }

    .logo{
        left: 0;
        transform: none;
        width: 160px
    }

    .c-menu-desktop__link{
        padding: 15px 3px;
    }

}

@media (min-width: $tablet){

    .c-menu-desktop__link{
        padding: 15px 15px;
    }
}


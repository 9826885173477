.c-card{
    background: #fff;
    border-radius: 3px;
    overflow: hidden;
    display: block;
    max-width: 323px;
    margin: 0 auto;
    border: 1px solid transparent;
    
    @include animation-transition($anim-normal);
    
    @include box-shadow;
    
    &:hover{
        @include box-shadow-hover;
        text-decoration: none;
    }
    
    @media (min-width: $tablet-up){
        height: 485px;
    }
    
    @media (min-width: $tablet){
        height: 424px;
    }
    
    @media (min-width: $res-HD){
        height: 440px;
    }

    &:focus{
        border: 1px solid var(--primary);
    }
}


.c-card__image{    
    overflow: hidden;
    position: relative;
}

.c-card__image__item{
    position: relative;
    
}


.c-card__title{
    padding: 13px 13px 8px 13px;
    display: block;
    font-size: 17px;
    color: var(--gray-hard);
    font-weight: 500;
}

.c-card__description{
    font-size: 15px;
    padding: 0 13px 13px 13px;
    display: block;
    color: var(--gray-medium4)
}


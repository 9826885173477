.c-list__item{
    margin-bottom: 7px;
    display: table;

    &:last-child{
        margin-bottom: 0;
    }
}

.c-list__arrow{    
    position: relative;
    top: -2px;
    fill: var(--primary);
    width: 7px;
    height: 12px;
    display: table-cell;
}

.c-list__text{
    display: table-cell;
    padding-left: 8px;
}

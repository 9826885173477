
@mixin gradient-small{
    background: #38a8ff;
    background: linear-gradient(#33CCFF, #38a8ff);
    background: -webkit-linear-gradient(#33CCFF, #38a8ff);
    background: -o-linear-gradient(#33CCFF, #38a8ff);
    background: -moz-linear-gradient(#33CCFF, #38a8ff);
    background: -ms-linear-gradient(#33CCFF, #38a8ff);

}

@mixin gradient-big{
    background: linear-gradient(90deg, #209DFF 10%, #3cf 50%, #209DFF 180%);
    background: -webkit-linear-gradient(90deg, #209DFF 10%, #3cf 50%, #209DFF 180%);
    background: -o-linear-gradient(90deg, #209DFF 10%, #3cf 50%, #209DFF 180%);
    background: -moz-linear-gradient(90deg, #209DFF 10%, #3cf 50%, #209DFF 180%);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#33CCFF', endColorstr='#209CFF');/*For IE7-8-9*/
}

@mixin box-shadow{
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    -moz-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
    -o-box-shadow: 0 3px 6px 0 rgba(0,0,0,0.1);
}

@mixin box-shadow-hover{
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
    -webkit-box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
    -moz-box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
    -o-box-shadow: 0 4px 12px 0 rgba(0,0,0,.2);
}

@mixin box-shadow-lighter{
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
    -webkit-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
    -moz-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
    -o-box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
}

@mixin placeholder-White($color, $active){
    ::-webkit-input-placeholder { color: $color; opacity: 1; }
    ::-moz-placeholder { color: $color; opacity: 1;}
    :-ms-input-placeholder { color: $color; opacity: 1;}

    :focus::-webkit-input-placeholder{ color: $active; opacity: 1;}
    :focus::-moz-placeholder{ color: $active; opacity: 1;}
    :focus:-ms-input-placeholder{ color: $active; opacity: 1;}
}

@mixin alpha-BGcolor-White {
    background: rgba(255,255,255,0.3);    
}

@mixin animation-transition($velocity){
    -webkit-transition: all $velocity ease;
    -moz-transition: all $velocity ease;
    -ms-transition: all $velocity ease;
    -o-transition: all $velocity ease;
    transition: all $velocity ease;
}

@mixin transition-delay($velocity){
    -webkit-transition-delay: $velocity;
    -moz-transition-delay: $velocity;
    -ms-transition-delay: $velocity;
    -o-transition-delay: $velocity;
    transition-delay: $velocity;
}

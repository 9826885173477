.e-skip-content{
    background: #fff;
    color: #101010;
    font-size: 1rem;
    left: 0;
    text-decoration: none;
    top: 0;
    z-index: 10;
    border: 0;
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &:focus{
        border: 1px solid var(--primary);
        display: inline-flex;
        outline: 0;
        padding: 10px 20px;
        height: auto;
        overflow: visible;
        width: auto;
    }
}

.leitor-tela{
    text-indent: -99999px;
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;

    &:focus{
        position: relative;
        font-size: 20px;
        color: #000;
        display: block;
        width: 100%;
        height: auto;
        text-align: center;
        text-indent: 0;
    }
}

#video-lomadee-how-works::cue {
    background: #000;
    line-height: auto;

  }

  figcaption {
    text-align:center;
    margin-top:0.5rem;
}